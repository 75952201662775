<template>
  <el-row>
    <el-container style="height:calc(93.5vh);">
      <el-header class="header">
        更多
      </el-header>
      <el-container style="height:calc(93.5vh);">
        <el-container>
          <el-main class="main">
            <div class="content">
              <div class="top">
                <div class="title">
                  营业
                </div>
                <div class="card-box">
                  <router-link to="/succession" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/1.png" alt="">
                    </div>
                    <div class="name">
                      交班管理
                    </div>
                  </router-link>
                  <router-link to="/scheduling" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/2.png" alt="">
                    </div>
                    <div class="name">
                      排班管理
                    </div>
                  </router-link>
                  <router-link to="/business-report" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/3.png" alt="">
                    </div>
                    <div class="name">
                      业务报表
                    </div>
                  </router-link>
                  <router-link to="/goods-management" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/4.png" alt="">
                    </div>
                    <div class="name">
                      商品管理
                    </div>
                  </router-link>
                  <router-link to="/add-goods" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/5.png" alt="">
                    </div>
                    <div class="name">
                      新增商品
                    </div>
                  </router-link>
                  <router-link to="/service-management" class="card" >
                    <div class="icon">
                      <img src="@/assets/images/more/6.png" alt="">
                    </div>
                    <div class="name">
                      服务管理
                    </div>
                  </router-link>
                  <router-link to="/add-service" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/7.png" alt="">
                    </div>
                    <div class="name">
                      新增服务
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="top">
                <div class="title">
                  库存
                </div>
                <div class="card-box">
                  <router-link to="/inventory-query" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/8.png" alt="">
                    </div>
                    <div class="name">
                      库存查询
                    </div>
                  </router-link>
                  <router-link to="/inventory-warning" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/9.png" alt="">
                    </div>
                    <div class="name">
                      低库存预警
                    </div>
                    <div class="num" v-if="num">{{  num}}</div>
                  </router-link>
                  <router-link to="/stock-manage" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/10.png" alt="">
                    </div>
                    <div class="name">
                      入库管理
                    </div>
                  </router-link>
                  <!-- <router-link to="/stock-query" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/11.png" alt="">
                    </div>
                    <div class="name">
                      入库查询
                    </div>
                  </router-link>
                  <router-link to="/outbound-manage" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/12.png" alt="">
                    </div>
                    <div class="name">
                      出库管理
                    </div>
                  </router-link>
                  <router-link to="/outbound-query" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/13.png" alt="">
                    </div>
                    <div class="name">
                      出库查询
                    </div>
                  </router-link>
                  <router-link to="/stock-taking" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/14.png" alt="">
                    </div>
                    <div class="name">
                      库存盘点
                    </div>
                  </router-link>
                  <router-link to="taking-query" class="card">
                    <div class="icon">
                      <img src="@/assets/images/more/15.png" alt="">
                    </div>
                    <div class="name">
                      盘点查询
                    </div>
                  </router-link> -->
                </div>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>

    </el-container>
  </el-row>
</template>

<script>
import { mapState } from 'vuex'

  export default {
    data() {
      return {
        num:0
      }
    },
    computed:{
      ...mapState(['user'])
    },
    mounted(){
      this.kuchun()
    },
    methods: {
      //检查库存预警
      kuchun(){
        this.$http.post('api/store/stock/disnum').then(res =>{
            this.num = res.data.data
        })
      }

    }
  }

</script>

<style lang="less" scoped>
  .header {
    line-height: 50px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;
  }

  .main {
    .content {
      color: #666;
      padding: 0 20px;

      .top {
        .title {
          font-weight: bold;
          line-height: 50px;
        }

        .card-box {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          .card {
            position: relative;
            width: 18%;
            text-align: center;
            background-color: #fff;
            padding: 40px 0;
            margin: 0 2% 10px 0;

            .icon {
              display: inline-block;
              width: 30%;

              img {
                width: 100%;
              }
            }

            .name {
              margin-top: 20px;
            }

            .num {
              position: absolute;
              top: 35px;
              right: 45px;
              background-color: #f56c6c;
              color: #fff;
              padding: 1px 5px;
              border-radius: 20px;
            }
          }
        }

      }
    }
  }

  /deep/ .el-header {
    height: 50px !important;
  }

</style>
